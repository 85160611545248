<template>
    <properties-component v-bind="$props" @close="$emit('close')">
        <slot name="before"></slot>

        <b-form-group label-cols="4" :label="'Display|Afficher'|tr" v-if="component.useBinding">
            <select-cms-item-field :item-type="block.itemType" v-model="component.imageField" field-type="image"/>
        </b-form-group>

        <b-form-group v-if="!noImagePicker && !(component.useBinding && component.imageField)">
            <template #label>
                <btn-language-prop class="float-right" v-model="component" field="src"/>
                Image
            </template>
            <input-image v-model="component.languageProxy.src" show-edit @edit="showEditor" :size="category === 'logo' ? 'lg': ''" :category="category"/>
        </b-form-group>

        <sidebar-group title="Image Style|Style de l'image" :expanded.sync="styleExpanded" sub-group>
            <b-form-group :title="'Round Corners|Arrondir les angles'|tr">
                <btn-responsive-prop v-model="component" field="borderRadius" no-label/>
                <input-border-radius v-model="component.mediaProxy.borderRadius"/>
            </b-form-group>

            <template  v-if="!isCircular">
                <b-form-group :title="'Aspect Ratio|Ratio de l\'image'|tr">
                    <btn-responsive-prop v-model="component" field="aspectRatio" no-label/>
                    <input-aspect-ratio v-model="component.mediaProxy.aspectRatio"/>
                </b-form-group>
            </template>

            <b-form-group label-cols="4">
                <template #label>
                    <span v-tr>Resize|Ajuster</span>
                    <btn-responsive-prop v-model="component" field="objectFit"/>
                </template>
                <b-select v-model="component.mediaProxy.objectFit">
                    <option value="contain" v-tr>Contain|Contenir</option>
                    <option value="cover" v-tr>Cover|Couvrir</option>
                </b-select>
            </b-form-group>

            <b-row>
                <b-col cols="6">
                    <b-form-group>
                        <template #label>
                            <span>{{isCircular ? tr('Size|Taille') : tr('Width|Largeur')}}</span>
                            <btn-responsive-prop v-model="component" field="maxWidth" not-inline/>
                        </template>
                        <e-input type="number" v-model.number="component.mediaProxy.maxWidth" :min="20" :max="3000" placeholder="Auto" state="disabled"/>
                        <span class="unit">px</span>
                    </b-form-group>
                </b-col>
                <b-col v-if="!isCircular">
                    <b-form-group>
                        <template #label>
                            <span v-tr>Height|Hauteur</span>
                            <btn-responsive-prop v-model="component" field="maxHeight" not-inline style="left: -25px;"/>
                        </template>
                        <e-input type="number" v-model.number="component.mediaProxy.maxHeight" :min="20" :max="2000" placeholder="Auto" state="disabled"/>
                        <span class="unit">px</span>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-form-group label-cols="4" :label="'Filter|Filtre'|tr">
                <b-select v-model="component.filter">
                    <option value="none" v-tr>No Filter|Sans filtre</option>
                    <option value="grayscale" v-tr>Grayscale|Nuance de gris</option>
                    <option value="sepia" v-tr>Sepia|Sépia</option>
                    <option value="recolor-black" v-tr>Recolor Black|Recolorer en noir</option>
                    <option value="recolor-white" v-tr>Recolor White|Recolorer en blanc</option>
                </b-select>
            </b-form-group>

            <div v-if="editBackground" class="sidebar-group">
                <b-form-group label-cols="4" :label="'Background|Fond'|tr">
                    <b-select v-model="component.background">
                        <option value="none" v-tr>None|Sans</option>
                        <option value="color" v-tr>Color|Couleur</option>
                    </b-select>
                </b-form-group>

                <b-form-group v-if="component.background === `color`">
                    <input-color v-model="component.backgroundColor" allow-transparent button block use-css-var :label="'Background Color|Couleur de fond'"/>
                </b-form-group>

                <b-form-group label-cols="4">
                    <template #label>
                        <span v-tr>Spacing|Espace</span>
                        <btn-responsive-prop v-model="component" field="spacing"/>
                    </template>
                    <b-select v-model="component.mediaProxy.spacing">
                        <option value="0" v-tr>None|Sans</option>
                        <option v-for="n in 100" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
                    </b-select>
                </b-form-group>
            </div>

            <b-form-group label-cols="4">
                <template #label>
                    <span v-tr>Quality|Qualité</span>
                    <btn-responsive-prop v-model="component" field="quality"/>
                </template>
                <b-select v-model="component.mediaProxy.quality">
                    <option value="auto" v-tr>Auto</option>
                    <option value="sm" v-tr>Medium|Moyenne</option>
                    <option value="xs" v-tr>Small|Petite</option>
                </b-select>
            </b-form-group>
        </sidebar-group>

        <b-form-group v-if="!component.useBinding" :label="'Link|Lien'|tr">
            <input-link v-model="component.link" allow-empty/>
        </b-form-group>

        <b-form-group v-if="!component.useBinding" :label="'Describe image|Décrivez l\'image'|tr">
            <e-translation-input v-model="component.alt" maxlength="100" :placeholder="'Enter alt text for SEO|Texte alternatif pour le SEO'|tr" auto-translate no-margin/>
        </b-form-group>

<!--        <b-form-group label-cols="4" :label="'Rounded|Arrondi'|tr">-->
<!--            <b-select v-model="component.borderRadius">-->
<!--                <option value="0" v-tr>None|Sans</option>-->
<!--                <option v-for="n in 49" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>-->
<!--            </b-select>-->
<!--        </b-form-group>-->

        <slot></slot>

        <b-modal v-if="editorEnabled" v-model="editorVisible" body-bg-variant="black" body-class="p-0"
                  hide-footer hide-header no-close-on-backdrop scrollable size="lg">
            <e-image-filters v-model="component.src" enable-cropper @apply="applyFilters" @cancel="editorVisible = false"/>
        </b-modal>
    </properties-component>
</template>

<script>
import BtnLanguageProp from "@/components/editor/properties/components/btn-language-prop.vue";
import BtnResponsiveProp from "@/components/editor/properties/components/btn-responsive-prop.vue";
import PropertiesComponent from "@/components/editor/properties/components/properties-component.vue";
import SidebarGroup from "@/components/editor/properties/components/sidebar-group.vue";
import InputAspectRatio from "@/components/editor/properties/inputs/input-aspect-ratio.vue";
import InputBorderRadius from "@/components/editor/properties/inputs/input-border-radius.vue";
import InputColor from "@/components/editor/properties/inputs/input-color.vue";
import InputImage from "@/components/editor/properties/inputs/input-image.vue";
import InputLink from "@/components/editor/properties/inputs/input-link.vue";
import SelectCmsItemField from "@/components/editor/properties/inputs/select-cms-item-field.vue";
import EImageFilters from "../../../../../vue-components/components/e-image-filters.vue";
import EInput from "../../../../../vue-components/components/e-input.vue";
import ETranslationInput from "../../../../../vue-components/components/e-translation-input.vue";

// properties-component-image #hot-reload-debug
export default {
    name: `properties-component-image`,
    components: {
        BtnLanguageProp,
        InputAspectRatio,
        ETranslationInput,
        BtnResponsiveProp, SidebarGroup, InputColor, InputLink, SelectCmsItemField, InputBorderRadius, EImageFilters, InputImage, EInput, PropertiesComponent},
    extends: PropertiesComponent,
    props: {
        noImagePicker: {type: Boolean},
        category: { type: String, default: `image` },
        editBackground: {type: Boolean, default: true}
    },
    data() {
        return {
            editorVisible: false,
            editorEnabled: false,
            styleExpanded: false
        }
    },
    computed: {
        isCircular() {
            return this.component.borderRadius === `50%`;
        }
    },
    methods: {
        showEditor() {
            this.editorEnabled = true;
            this.editorVisible = true;
        },
        applyFilters({image, filters}) {
            this.component.src = image;
            this.component.filters = filters;
            this.editorVisible = false;
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
