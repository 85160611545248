<template>
    <div ref="imageFilter" v-if="image" class="e-image-filter">
        <div class="pb-2 px-2 filter-header">
            <div class="text-left">
                <b-btn @click="$emit('cancel', $event)" variant="transparent" class="back-button text-white f-9">
                    <i class="fa-chevron-left mr-1" :class="iconPrefixTheme"></i>
                    <!--                    <span v-tr>Cancel|Annuler</span>-->
                </b-btn>
                <b-btn @click="setToDefault()" variant="transparent" class="mx-3 text-white f-9">
                    <span v-tr>Reset|Reinit.</span>
                </b-btn>
                <b-btn @click="rotate(-90)" variant="transparent" class="text-white f-10" :title="'Rotate Left|Pivoter à gauche'|tr">
                    <i class="fa-rotate-left" :class="iconPrefixTheme"></i>
                </b-btn>
                <b-btn @click="rotate(90)" variant="transparent" class="text-white f-10" :title="'Rotate Right|Pivoter à droite'|tr">
                    <i class="fa-rotate-right" :class="iconPrefixTheme"></i>
                </b-btn>
                <template v-if="!isMobileLayout">
                    <b-btn @click="zoom(-1)" variant="transparent" class="text-white f-10 ml-3" :title="'Zoom out|Zoomer'|tr">
                        <i class="fa-magnifying-glass-minus" :class="iconPrefixTheme"></i>
                    </b-btn>
                    <b-btn @click="zoom(1)" variant="transparent" class="text-white f-10" :title="'Zoom in|Dézoomer'|tr">
                        <i class="fa-magnifying-glass-plus" :class="iconPrefixTheme"></i>
                    </b-btn>
                    <b-btn @click="adjustImage()" variant="transparent" class="text-white f-10" :title="'Adjust|Ajuster'|tr">
                        <i class="fa-maximize" :class="iconPrefixTheme"></i>
                    </b-btn>
                </template>
                <b-btn v-if="smallLoading" variant="transparent" class="text-white f-10">
                    <i class="fa-spin fa-loader" :class="iconPrefixTheme"></i>
                </b-btn>
                <e-button-async @async-click="apply" class="apply-button float-right" variant="light-white">
                    <span v-tr>Apply|Appliquer</span>
                </e-button-async>
            </div>
        </div>

        <!-- Image -->
        <div class="image-zone">
            <div ref="imageContainer" class='image-container' @mousedown="onMouseStart" @mousemove="onDragging" @mouseup="onStopDragging" @mouseleave="onStopDragging"
                 @wheel.prevent="onWheelScrolled"
                 v-touch:start="onTouchStart" v-touch:moving="onDragging" v-touch:end="onStopDragging" :style="{ 'height': display.zoneHeight + 'px' }">

                <canvas ref='canvasOverlay' class='preview-overlay' @dragstart.prevent="" :height="display.zoneHeight"/>
                <canvas ref='canvasPreview' class='preview-image' @dragstart.prevent="" :height="display.zoneHeight" :style="!effectHidden ? filters: {}"/>

                <div class="badge-original" :class="{ 'visible': effectHidden }" v-tr>Original|Original</div>
            </div>
        </div>

        <!--        For debug only -->
        <!--        <b-img center class="my-3" v-if="ready" style="outline: 1px dashed orange !important; outline-offset: -1px !important; width: 250px;" :src="getImageAsBase64()"></b-img>-->

        <div v-if="tabSelected === 'presets'" class="filter-tab">
            <h5 class="my-2 text-center text-white"><small v-tr>Presets|Filtres</small></h5>
            <div class="preset-list x-scroll" ref="filterList" @mousewheel="scrollFilterList">
                <div class="preset" v-for='preset in presets' :key="preset.index" @click='selectAndLoadPreset(preset)'
                     :class="[(selectedPreset && (selectedPreset.name === preset.name)) ? 'selected' : '']">
                    <div class="name">{{ preset.name }}</div>
                    <img crossOrigin="anonymous" alt="preset" :src="thumbnailImage" :style='makeFilter(preset.filterSet)' loading="lazy">
                </div>
            </div>
        </div>
        <div v-else-if="tabSelected === 'rotation'" class="filter-tab">
            <h5 class="my-2 text-center text-white"><small v-tr>Rotation|Rotation</small></h5>
            <div class="adjustment-filter-list x-scroll mt-2">
                <div class="filter selected">
                    <i class="icon fa-angle" :class="iconPrefixTheme"></i>
                </div>
            </div>
            <div class="adjustment-filter">
                <div class="filter-container">
                    <div class="floating-fix mx-2 position-relative">
                        <span class="value" :style="{ left: (50 + (transform.rotation / 360) * 100) + '%' }">
                            {{ transform.rotation }}°
                        </span>
                        <div class="default" :style="{ left: 50 + '%' }"></div>
                    </div>
                    <input type="range" v-model="transform.rotation" @input="needRefreshImage" min="-180" :max="180" :step="5"/>
                </div>
            </div>
        </div>
        <div v-else-if="tabSelected === 'filters'" class="filter-tab">
            <h5 class="my-2 text-center text-white"><small v-tr>Adjust|Ajuster</small></h5>
            <div class="adjustment-filter-list x-scroll">
                <div class="filter" v-for='filter in adjustmentFilters' :key="'filter-' + filter.name" @click='selectAdjustmentFilter(filter)'
                     :class="[(selectedFilter && (selectedFilter.name === filter.name)) ? 'selected' : '']">
                    <i class="icon " :class="filter.icon"></i>
                    <div class="name">{{ filter.name |tr }}</div>
                </div>
            </div>
            <div class="adjustment-filter">
                <div class="filter-container">
                    <div class="floating-fix mx-2 position-relative">
                        <span class="value" :style="{ left: currentMarkerPercent + '%' }">
                            {{ ((filterFunctions[selectedFilter.filter] || selectedFilter.default) - selectedFilter.default) * 100|round }}
                        </span>
                        <div class="default" :style="{ left: defaultMarkerPosition + '%' }"></div>
                    </div>
                    <input type="range" v-model="filterFunctions[selectedFilter.filter]" :min="selectedFilter.min" :max="selectedFilter.max" :step="selectedFilter.step"/>
                </div>
            </div>
        </div>
        <div class="filter-tabs px-2 py-lg-2">
            <b-btn variant="transparent" :class="tabSelected === 'presets' ? 'selected': ''" class="btn-tab" @click="tabSelected = 'presets'" v-tr>Presets|Filtres</b-btn>
            <b-btn variant="transparent" :class="tabSelected === 'rotation' ? 'selected': ''" class="btn-tab" @click="tabSelected = 'rotation'" v-tr>Rotation|Rotation</b-btn>
            <b-btn variant="transparent" :class="tabSelected === 'filters' ? 'selected': ''" class="btn-tab" @click="tabSelected = 'filters'" v-tr>Adjust|Ajuster</b-btn>
        </div>
    </div>
</template>

<script>
import EButtonAsync from "./e-button-async.vue";

export default {
    name: `e-image-filters`,
    components: {EButtonAsync},
    props: {
        value: {type: String},
        defaultSrc: {type: String},
        startFilters: {type: Object},
        enableCropper: {type: Boolean},
        lockAspectRatio: {type: Boolean},
        aspectRatio: {type: Number, default: 1},
        iconPrefixTheme: {type: String, default: `fal`}
    },
    data() {
        return {
            loading: false,
            smallLoading: false,
            image: null,
            filterFunctions: null,
            tabSelected: `presets`,
            width: 0,
            height: 0,
            refresh: false,
            ready: false,
            dragging: {
                byMouse: false,
                byTouch: false,
                startX: 0,
                startY: 0,
                deltaX: 0,
                deltaY: 0,
                secondTouch: {
                    active: false,
                    lastDistanceDelta: 0,
                    lastDistance: 0
                }
            },
            transform: {
                translateX: 0,
                translateY: 0,
                rotation: 0,
                zoom: 1
            },
            adjustAnimation: {
                active: false,
                progress: 0,
                startX: 0,
                startY: 0,
                destinationX: 0,
                destinationY: 0
            },
            display: {
                zoneWidth: 790, // calculated later
                zoneHeight: 400,
                width: 350,
                height: 350,
                adjustedZoom: 5
            },
            export: {
                width: 1500,
                height: 1500
            },
            canvasPreview: null,
            canvasOverlay: null,
            thumbnailCanvas: null,
            thumbnailImage: null,
            selectedPreset: null,
            effectHidden: false,
            selectedFilter: null,
            presets: {
                default: {name: `Default`, filterSet: {}},
                sunshine: {name: `Sunshine`, filterSet: {brightness: 1.6, sepia: 0.1, saturate: 1.1, contrast: 1.1}},
                summer: {name: `Summer`, filterSet: {brightness: 1.3, sepia: 0.1, saturate: 1.05, contrast: 1.2}},
                ariannev: {name: `Arianne V`, filterSet: {brightness: 1.2, hueRotate: -0.05, saturate: 0.95, contrast: 1.1}},
                vintage: {name: `Vintage`, filterSet: {brightness: 1.1, hueRotate: -0.1, sepia: 0.3, saturate: 1.6}},
                brannes: {name: `Brannes`, filterSet: {sepia: 0.5, contrast: 1.4}},
                nashville: {name: `Nashville`, filterSet: {sepia: 0.2, contrast: 1.2, brightness: 1.05, saturate: 1.2}},
                lofi: {name: `Lo-Fi`, filterSet: {saturate: 1.1, contrast: 1.5}},
                spectacular: {name: `Spectacular`, filterSet: {hueRotate: 0.1, brightness: 1.1, contrast: 1.2}},
                toaster: {name: `Toaster`, filterSet: {contrast: 1.5, brightness: 0.9}},
                xpro2: {name: `X-pro II`, filterSet: {sepia: 0.3, brightness: 1.1}},
                moon: {name: `Moon`, filterSet: {grayscale: 1, contrast: 1.1, brightness: 1.1}}
            }
        }
    },
    computed: {
        defaultMarkerPosition() {
            return ((this.selectedFilter.default - this.selectedFilter.min) / (this.selectedFilter.max - this.selectedFilter.min)) * 100;
        },
        currentMarkerPercent() {
            return (((this.filterFunctions[this.selectedFilter.filter] || this.selectedFilter.default) - this.selectedFilter.min) / (this.selectedFilter.max - this.selectedFilter.min)) * 100;
        },
        filters() {
            if (this.effectHidden) {
                return this.makeFilter(null);
            } else {
                return this.makeFilter();
            }
        },
        adjustmentFilters() {
            return [
                {name: `Brightness|Luminosité`, icon: this.iconPrefixTheme + ` fa-brightness`, filter: `brightness`, min: 0, max: 2, step: 0.01, default: 1},
                {name: `Contrast|Contraste`, icon: this.iconPrefixTheme + ` fa-circle-half-stroke`, filter: `contrast`, min: 0, max: 2, step: 0.01, default: 1},
                {name: `Saturate|Saturation`, icon: this.iconPrefixTheme + ` fa-droplet`, filter: `saturate`, min: 0, max: 2, step: 0.05, default: 1},
                {name: `Sepia`, icon: this.iconPrefixTheme + ` fa-circle-dashed`, filter: `sepia`, min: 0, max: 1, step: 0.05, default: 0},
                {name: `Hue|Teinte`, icon: this.iconPrefixTheme + ` fa-palette`, filter: `hueRotate`, min: -1, max: 1, step: 0.02, default: 0},
                {name: `Grayscale|Nuances gris`, icon: this.iconPrefixTheme + ` fa-diagram-venn`, filter: `grayscale`, min: 0, max: 1, step: 0.05, default: 0}
            ]
        },
        translationBoundingBox() {
            // Define the translation bounding box, to constraint area within the image
            const widthRemaining = this.image.width * this.transform.zoom - this.display.width;
            const heightRemaining = this.image.height * this.transform.zoom - this.display.height;

            return {
                left: -widthRemaining / this.transform.zoom / 2,
                right: widthRemaining / this.transform.zoom / 2,
                top: -heightRemaining / this.transform.zoom / 2,
                bottom: heightRemaining / this.transform.zoom / 2
            }
        }
    },
    created() {
        this.filterFunctions = this.defaultFilterValues();
        if (this.startFilters) {
            this.applyFilterPreset(this.startFilters);
        }
        this.selectedFilter = this.adjustmentFilters[0];
        this.loadBase64Image(this.value || this.defaultSrc);
    },
    mounted() {
        window.addEventListener(`resize`, () => {
            this.initImageSize();
            setTimeout(() => {
                this.initImageSize();
            }, 250);
        });
        this.canvasPreview = this.$refs.canvasPreview;
        this.canvasOverlay = this.$refs.canvasOverlay;
        this.redrawLoop();
    },
    watch: {
        value(value) {
            this.loadBase64Image(value);
        }
    },
    methods: {
        redrawLoop() {
            this.updateAdjustAnimation();

            // Only refresh when requested
            if (this.refresh) {
                this.refreshImage();

                // Theoretically it should not be necessary
                this.refreshOverlay();
                this.refresh = false;
            }

            // To target 60 Fps
            requestAnimationFrame(this.redrawLoop);
        },
        updateAdjustAnimation() {
            if (!this.adjustAnimation.active || this.adjustAnimation.progress >= 1)
                return;

            this.adjustAnimation.progress += 0.05;
            // Because linear animations are boring
            const bezierProgress = this.easeOutCubic(this.adjustAnimation.progress);

            // Lerp between start and destination
            this.transform.translateX = this.adjustAnimation.startX + (this.adjustAnimation.destinationX - this.adjustAnimation.startX) * bezierProgress;
            this.transform.translateY = this.adjustAnimation.startY + (this.adjustAnimation.destinationY - this.adjustAnimation.startY) * bezierProgress;

            this.needRefreshImage();
        },
        loadBase64Image(base64) {
            this.image = new Image();
            this.image.crossOrigin = `anonymous`;
            this.image.src = base64;
            this.image.onload = () => {
                this.width = this.image.width;
                this.height = this.image.height;
                this.$emit(`load`);

                const thumbnailCanvas = document.createElement(`canvas`);
                thumbnailCanvas.width = 240;
                thumbnailCanvas.height = 200;
                let context = thumbnailCanvas.getContext(`2d`);

                // To keep ratio while drawing thumbnail
                const size = this.getRectangleSizeWithRatio(thumbnailCanvas.width, thumbnailCanvas.height, this.image.width, this.image.height);

                context.drawImage(this.image, size.left, size.top, size.width, size.height, 0, 0, thumbnailCanvas.width, thumbnailCanvas.height);
                this.thumbnailImage = thumbnailCanvas.toDataURL(`image/jpeg`, 0.6);

                this.initImageSize();
                this.adjustZoomToDefault();
                this.ready = true;
            };

            this.image.onerror = () => {
                this.$emit(`load`);
            };
        },
        onMouseStart(event) {
            this.dragging.startX = Math.round(event.clientX);
            this.dragging.startY = Math.round(event.clientY);
            this.dragging.deltaX = 0;
            this.dragging.deltaY = 0;
            this.dragging.byMouse = true;
        },
        onTouchStart(event) {
            if (!event.touches)
                return;

            const firstTouch = event.touches[0];
            this.dragging.startX = Math.round(firstTouch.clientX);
            this.dragging.startY = Math.round(firstTouch.clientY);
            this.dragging.deltaX = 0;
            this.dragging.deltaY = 0;
            this.dragging.byTouch = true;

            if (event.touches.length > 1) {
                const secondTouch = event.touches[0];
                this.dragging.secondTouch.lastDistance = Math.round(Math.abs(secondTouch.clientX - firstTouch.clientX));
                this.dragging.secondTouch.active = true;
            } else {
                this.dragging.secondTouch.active = false;
            }
            this.dragging.secondTouch.lastDistance = 0;
        },
        onDragging(event) {
            if (this.dragging.byTouch || this.dragging.byMouse) {
                let eventHolder = event;

                if (this.dragging.byTouch) {
                    if (event.touches.length > 1 || this.dragging.secondTouch.active) {
                        // Pinch or rotation
                        const firstTouch = event.touches[0];
                        const secondTouch = event.touches[1];

                        if (!this.dragging.secondTouch.active) {
                            this.dragging.secondTouch.lastDistance = Math.round(Math.abs(secondTouch.clientX - firstTouch.clientX));
                            this.dragging.secondTouch.active = true;

                            // Disable previous translation
                            this.dragging.deltaX = 0;
                            this.dragging.deltaY = 0;
                        }

                        const newDistance = Math.round(Math.abs(secondTouch.clientX - firstTouch.clientX));
                        this.dragging.secondTouch.lastDistanceDelta = newDistance - this.dragging.secondTouch.lastDistance;
                        this.zoom(this.dragging.secondTouch.lastDistanceDelta / 5);
                        this.dragging.secondTouch.lastDistance = newDistance;
                        return;
                    } else {
                        eventHolder = event.touches[0];
                    }
                }

                // Rotate vector when image is rotated, otherwise user becomes crazy
                const vector = this.rotateVector((eventHolder.clientX - this.dragging.startX) / this.transform.zoom, (eventHolder.clientY - this.dragging.startY) / this.transform.zoom);
                this.dragging.deltaX = vector.x;
                this.dragging.deltaY = vector.y;

                this.needRefreshImage();
            }
        },
        onStopDragging() {
            if (this.dragging.byMouse || this.dragging.byTouch) {
                if (Math.abs(this.dragging.deltaX) < 10 && Math.abs(this.dragging.deltaY) < 10) {
                    this.hideEffects();
                }

                this.transform.translateX += this.dragging.deltaX;
                this.transform.translateY += this.dragging.deltaY;

                this.adjustInBoundingBox();

                this.dragging.deltaX = 0;
                this.dragging.deltaY = 0;
                this.dragging.byMouse = false;
                this.dragging.byTouch = false;
                this.dragging.secondTouch.active = false;
                this.needRefreshImage();
            }
        },
        adjustInBoundingBox() {
            // Not perfect with non 90 degree rotation, but it still constrains the image so better than nothing
            // Make sure the ending zone is within the image area with a nice animation
            this.adjustAnimation.startX = this.transform.translateX;
            this.adjustAnimation.startY = this.transform.translateY;
            this.adjustAnimation.destinationX = Math.max(Math.min(this.transform.translateX, this.translationBoundingBox.right), this.translationBoundingBox.left);
            this.adjustAnimation.destinationY = Math.max(Math.min(this.transform.translateY, this.translationBoundingBox.bottom), this.translationBoundingBox.top);
            this.adjustAnimation.active = true;
            this.adjustAnimation.progress = 0;

            // Without animation
            // this.transform.translateX = Math.max(Math.min(this.transform.translateX, this.translationBoundingBox.right), this.translationBoundingBox.left);
            // this.transform.translateY = Math.max(Math.min(this.transform.translateY, this.translationBoundingBox.bottom), this.translationBoundingBox.top);
        },
        rotateVector(x, y) {
            const angle = this.transform.rotation * Math.PI / 180; // convert to radian
            return {
                x: x * Math.cos(angle) - y * Math.sin(angle),
                y: x * Math.sin(angle) + y * Math.cos(angle)
            };
        },
        needRefreshImage() {
            this.refresh = true;
        },
        refreshOverlay() {
            const canvas = this.$refs.canvasOverlay;
            canvas.width = this.display.zoneWidth;
            canvas.height = this.display.zoneHeight;

            const context = canvas.getContext(`2d`);
            context.save(); // save current transformation state to avoid any issue for next frame
            context.clearRect(0, 0, canvas.width, canvas.height);

            context.filleStyle = `#000000`;
            context.globalAlpha = 0.5;
            const borderWidth = (canvas.width - this.display.width) / 2;
            const borderHeight = (canvas.height - this.display.height) / 2;
            context.fillRect(0, 0, canvas.width, borderHeight);
            context.fillRect(0, borderHeight, borderWidth, canvas.height - (borderHeight * 2));
            context.fillRect(canvas.width - borderWidth, borderHeight, borderWidth, canvas.height - (borderHeight * 2));
            context.fillRect(0, canvas.height - borderHeight, canvas.width, borderHeight);

            // Move to canvas center
            context.translate(canvas.width / 2, canvas.height / 2);
            context.lineWidth = 2;
            context.strokeStyle = `#ffffff`;
            context.globalAlpha = 0.7;
            context.strokeRect(-this.display.width / 2, -this.display.height / 2, this.display.width, this.display.height);

            context.restore();
        },
        refreshImage() {
            const canvas = this.$refs.canvasPreview;
            canvas.width = this.display.zoneWidth;
            canvas.height = this.display.zoneHeight;

            const context = canvas.getContext(`2d`);
            context.save(); // save current transformation state to avoid any issue for next frame
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.fillStyle = `#000000`;
            context.fillRect(0, 0, canvas.width, canvas.height);

            // Filters are faster in CSS for the preview
            // context.filter = this.filters.filter;

            context.translate(canvas.width / 2, canvas.height / 2);
            context.rotate(-this.transform.rotation * Math.PI / 180);
            context.scale(this.transform.zoom, this.transform.zoom);
            context.translate(-this.image.width / 2, -this.image.height / 2);

            let left = (this.transform.translateX + this.dragging.deltaX);
            let top = (this.transform.translateY + this.dragging.deltaY);

            context.drawImage(this.image, left, top, this.image.width, this.image.height);
            context.restore();
        },
        getImageAsBase64() {
            const canvas = document.createElement(`canvas`);
            canvas.width = this.export.width;
            canvas.height = this.export.height;

            const context = canvas.getContext(`2d`);
            context.filter = this.filters.filter;

            const scale = this.transform.zoom * this.export.width / this.display.width;

            context.translate(canvas.width / 2, canvas.height / 2);
            context.rotate(-this.transform.rotation * Math.PI / 180);
            context.scale(scale, scale);
            context.translate(-this.image.width / 2, -this.image.height / 2);

            let left = (this.transform.translateX + this.dragging.deltaX);
            let top = (this.transform.translateY + this.dragging.deltaY);

            context.drawImage(this.image, left, top, this.image.width, this.image.height);

            return canvas.toDataURL(`image/jpeg`, 0.8);
        },
        initImageSize() {
            const width = this.$refs.imageContainer.offsetWidth;

            this.display.zoneWidth = width;
            this.display.width = 350;
            this.display.height = 350;

            // Adjust height if necessary
            if (width < 400) {
                if (window.innerHeight > 600) {
                    this.display.width = 300;
                    this.display.height = 300;
                } else {
                    this.display.width = 230;
                    this.display.height = 230;
                }
            }
            this.display.zoneHeight = this.display.height + 50;

            this.display.adjustedZoom = Math.max(this.display.width / this.image.width, this.display.height / this.image.height);

            this.needRefreshImage();
        },
        onWheelScrolled(event) {
            if (event.deltaY > 0) {
                this.zoom(-1);
            } else {
                this.zoom(1);
            }
        },
        zoom(deltaZoom) {
            // To offer enough precision to the user
            if (this.transform.zoom < 0.5) {
                deltaZoom *= 0.01;
            } else if (this.transform.zoom < 0.8) {
                deltaZoom *= 0.02;
            } else if (this.transform.zoom < 1.2) {
                deltaZoom *= 0.05;
            } else if (this.transform.zoom < 2) {
                deltaZoom *= 0.1;
            } else if (this.transform.zoom < 8) {
                deltaZoom *= 0.2;
            }

            this.transform.zoom = Math.min(Math.max(this.transform.zoom + deltaZoom, this.display.adjustedZoom), 5);
            this.adjustInBoundingBox();
            this.needRefreshImage();
        },
        adjustImage() {
            this.adjustZoomToDefault();
            this.transform.translateX = 0;
            this.transform.translateY = 0;
            this.needRefreshImage();
        },
        adjustZoomToDefault() {
            this.transform.zoom = this.display.adjustedZoom;
            this.adjustInBoundingBox();
        },
        rotate(rotation) {
            this.transform.rotation = (this.transform.rotation + rotation) % 360;
            this.needRefreshImage();
        },
        hideEffects() {
            this.effectHidden = true;
            setTimeout(() => {
                this.effectHidden = false;
            }, 1000);
        },
        apply(callback = () => {}) {
            setTimeout(() => {
                this.$emit(`apply`, {
                    image: this.getImageAsBase64(),
                    filters: this.filterFunctions
                });
                callback();
            }, 10);
        },
        makeFilter(filterSet) {
            if (!filterSet) {
                filterSet = this.filterFunctions;
            }

            let filterString = ``;
            let defaultFilterValues = this.defaultFilterValues();
            for (let filterFunc in filterSet) {
                if (filterSet[filterFunc] !== defaultFilterValues[filterFunc]) {
                    if (filterFunc === `hueRotate`) {
                        filterString = filterString + `hue-rotate(` + (filterSet[filterFunc] * 100) + `deg) `;
                    } else if (filterFunc === `blur`) {
                        filterString = filterString + filterFunc + `(` + filterSet[filterFunc] + `px) `;
                    } else {
                        filterString = filterString + filterFunc + `(` + filterSet[filterFunc] + `) `;
                    }
                }
            }

            return {filter: filterString};
        },
        setToDefault() {
            this.filterFunctions = this.defaultFilterValues();
        },
        defaultFilterValues() {
            return {
                grayscale: 0,
                sepia: 0,
                saturate: 1,
                hueRotate: 0,
                invert: 0,
                brightness: 1,
                contrast: 1,
                blur: 0,
                opacity: 1
            }
        },
        selectAndLoadPreset(preset) {
            if (preset && !this.smallLoading) {
                this.smallLoading = true;
                setTimeout(() => {
                    this.applyFilterPreset(preset.filterSet);
                    this.selectedPreset = preset;
                    this.smallLoading = false;
                }, 1);
            }
        },
        applyFilterPreset(filters) {
            this.filterFunctions = this.defaultFilterValues();
            if (filters) {
                for (let filter in filters) {
                    if (this.filterFunctions.hasOwnProperty(filter)) {
                        const value = parseFloat(filters[filter]);
                        if (!isNaN(value)) {
                            this.filterFunctions[filter] = value;
                        }
                    }
                }
            }
        },
        selectAdjustmentFilter(filter) {
            this.selectedFilter = filter;
        },
        scrollFilterList(event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
            // noinspection JSSuspiciousNameCombination
            this.$refs.filterList.scrollLeft += event.deltaY;
        },
        getRectangleSizeWithRatio(maxWidth, maxHeight, width, height) {
            const ratio = Math.min(width / maxWidth, height / maxHeight);
            if (width / maxWidth > height / maxHeight) {
                return {
                    left: (width - maxWidth * ratio) / 2,
                    top: 0,
                    width: maxWidth * ratio,
                    height: height,
                    ratio: ratio
                };
            } else {
                return {
                    left: 0,
                    top: (height - maxHeight * ratio) / 2,
                    width: width,
                    height: maxHeight * ratio,
                    ratio: ratio
                };
            }
        },
        easeOutCubic(x) {
            // From https://easings.net/fr#easeOutCubic
            return 1 - Math.pow(1 - x, 3);
        }
    }
}
</script>

<style lang="scss" scoped>
.filter-header {
    position: relative;
    z-index: 2;
    background-color: rgba(black, 0.9);
    padding-top: 0.5rem;
    padding-top: calc(0.5rem + env(safe-area-inset-top));
}

.image-zone {
    width: 100%;
    overflow: hidden;
    touch-action: none;
}

.image-container {
    position: relative;
    text-align: center;
    margin: 0 auto;
    max-width: 100%;
    user-select: none;
    background-color: #222;
    width: 100%;
    height: 400px;

    canvas {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        &.preview-overlay {
            z-index: 1;
        }
    }
}

.badge-original {
    position: absolute;
    top: 30px;
    margin-top: env(safe-area-inset-top);
    border-radius: 5px;
    background-color: rgba(black, 0.95);
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 3px;
    font-size: 7pt;
    color: white;
    opacity: 0;
    transition: opacity 150ms;
    width: 80px;
    left: calc(50% - 40px);
    z-index: 15;

    &.visible {
        opacity: 0.9;
    }
}

.adjustment-filter-list,
.preset-list {
    display: flex;
    flex-grow: inherit;
    overflow-x: auto;
    overflow-y: hidden;

    @media (max-width: 1100px) {
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.filter-tab {
    z-index: 1;
    position: relative;
    height: 155px;
    background-color: rgba(black, 0.9);
    user-select: none;
}

.preset-list {
    .preset {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 120px;
        min-width: 120px;
        position: relative;
        margin-left: 5px;
        margin-bottom: 5px;
        cursor: pointer;

        img {
            width: 100%;
            height: 100px;
            object-fit: cover;
            border-radius: 5px;
        }

        .name {
            display: block;
            text-align: center;
            color: #999;
            padding: 3px;
            font-size: 8pt;
            letter-spacing: 0.5px;
        }

        &.selected {
            .name {
                color: white;
            }
        }

        &:hover {
            .name {
                color: #ddd;
            }
        }
    }
}

.adjustment-filter-list {
    padding-top: 5px;

    &:before {
        content: '';
        padding-left: 5px;
    }

    &:after {
        content: '';
        padding-right: 5px;
    }

    .filter {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 50px;
        min-width: 80px;
        position: relative;
        //margin-left: 5px;
        //margin-bottom: 5px;
        gap: 5px;
        color: #999;
        text-align: center;
        cursor: pointer;

        .icon {
            font-size: 24px;
        }

        .name {
            display: block;
            padding: 3px 0;
            font-size: 8pt;
            letter-spacing: 0.5px;
        }

        &.selected {
            color: white;
        }

        &:hover {
            color: #ddd;
        }
    }
}

.adjustment-filter {
    padding-bottom: 20px;

    .filter-container {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
        position: relative;
        padding-top: 20px;

        .default {
            position: absolute;
            display: block;
            width: 4px;
            height: 4px;
            margin-left: -2px;
            border-radius: 2px;
            background-color: white;
            top: 20px;
            z-index: 0;
        }

        .value {
            color: white;
            font-size: 10pt;
            position: absolute;
            text-align: center;
            width: 80px;
            margin-left: -40px;
            top: -15px;
        }

        input {
            width: 100%;
            position: relative;
        }
    }
}

.filter-tabs {
    .btn-tab {
        color: #999;
        width: 33%;
        letter-spacing: 0.7px;

        &.selected {
            color: white;
        }
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.x-scroll {
    &::-webkit-scrollbar {
        display: none;
    }
}
</style>
