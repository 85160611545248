<template>
    <div class="input-aspect-ratio" style="margin-left: -3px; margin-right: -10px;">
        <slot name="prepend"></slot>
        <btn-radio v-for="(preset, index) in presets" :key="`ratio-${index}`" v-model="currentAspectRatio" :option="preset"
                   :style="{width: `${40 * preset.value}px`, height: `40px`}">
        </btn-radio>
    </div>
</template>

<script>
import BtnRadio from "@/components/editor/properties/inputs/btn-radio.vue";

// input-aspect-ratio #hot-reload-debug
export default {
    name: `input-aspect-ratio`,
    components: {BtnRadio},
    props: {
        value: {type: [String, Number]},
    },
    computed: {
        presets() {
            let presets = [];
            presets.push({label: `Auto`, value: `auto`});
            presets.push({label: `1:1` , value: 1});
            presets.push({label: `16:9` , value: 16/9});
            presets.push({label: `9:16` , value: 9/16});
            presets.push({label: `4:3` , value: 4/3});
            presets.push({label: `3:4` , value: 3/4});
            return presets;
        },
        currentAspectRatio: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
