<template>
    <b-btn :type="type" :class="{'async-loading': loading}" :variant="variant" :size="size" :block="block" :disabled="disabled || loading" @click="buttonClicked">
        <div v-if="loading" class="loader-btn"></div>
        <slot></slot>
    </b-btn>
</template>

<script>
    export default {
        name: `e-button-async`,
        props: [`type`, `disabled`, `variant`, `size`, `block`],
        data() { // function is import so each instance has its own data object
            return {
                loading: false
            }
        },
        methods: {
            buttonClicked(data) {
                this.loading = true;
                this.$emit(`async-click`, () => {
                    this.loading = false;
                }, data);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .loader-btn {
        margin-bottom: -4px;
        margin-right: 5px;
        display: inline-block;
        border: 3px solid #f3f3f3;
        border-top: 3px solid #5c6c77;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>
